import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import StoriesPagination from '../components/Stories/StoriesPagination'
import StoriesItem from '../components/Stories/StoriesItem'
import StoriesPlayer from '../components/Stories/StoriesPlayer'

const StoriesPage = ({ location, pageContext, data }) => {
  const page = data.allPrismicStoriesSingle.edges[0].node
  const { next } = pageContext
  return (
    <Layout headerColor="smoke" title="Podcast" hideFooter titleLink="/stories">
      <SEO
        title={page.data.meta_title || page.data.heading}
        description={page.data.meta_description || ''}
        location={location}
      />
      <div className="pt-hh">
        <div className="md:flex">
          <div className="md:w-1/2 bg-green">
            <div className="md:sticky md:top-hh md:left-0 md:h-stories-player-single">
              <StoriesPlayer story={page} alt />
            </div>
          </div>
          <div className="md:w-1/2">
            <StoriesItem story={page} location={location} alt />
            <StoriesPagination next={next} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

StoriesPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const StoriesQuery = graphql`
  query StoriesByUid($uid: String!) {
    allPrismicStoriesSingle(filter: { uid: { eq: $uid } }) {
      edges {
        node {
          uid
          data {
            audio_file {
              url
            }
            date(formatString: "DD/MM/YY")
            heading
            subheading
            episode_number
            body {
              html
            }
            image {
              alt
              url
            }
            media_links {
              link {
                url
              }
              link_heading
            }
            meta_title
            meta_description
            thumbnail_secondary_colour
            thumbnail_secondary {
              alt
              url
            }
            image {
              alt
              url
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default StoriesPage
