import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import StoriesSubheading from './StoriesSubheading'

const StoriesPagination = ({ next }) => {
  if (!next) return null
  return (
    <div className="h-1/2-screen relative">
      <div className="absolute inset-0">
        <AniLink
          className="relative w-full h-full block target-link px-ogs lg:px-2x-ogs bg-peach"
          fade
          to={'/podcast/' + next.uid}
        >
          <StoriesSubheading heading={next.data.subheading} ratio>
            <div className="flex items-center">
              <div className="w-1/2">Next Story</div>
              <div className="w-1/2">
                {next.data.episode_number && next.data.episode_number}
              </div>
            </div>
          </StoriesSubheading>
        </AniLink>
      </div>
    </div>
  )
}

export default StoriesPagination
